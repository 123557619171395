import { render, staticRenderFns } from "./index.vue?vue&type=template&id=47d91086&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiFormField: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Ui/Form/Field.vue').default,UiButton: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Ui/Button/index.vue').default,UiSearchTabs: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Ui/Search/Tabs.vue').default,UiForm: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Ui/Form/index.vue').default,UiSearchResult: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Ui/Search/Result.vue').default,UiSearchHistory: require('/codebuild/output/src3562520020/src/med-el-hearpeers/components/Ui/Search/History.vue').default})
